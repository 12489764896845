// @ts-ignore
import bootstrap from "./bootstrap"
import React from "react";
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing"

console.log("Version: " + (process.env.ENV_APP_VERSION || "Localhost"))

// eslint-disable-next-line @typescript-eslint/no-empty-function
Sentry.init({
  dsn: window?._env_?.SENTRY_URL,
  integrations: [
    new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    ),
  }), new Sentry.Replay()],
  tracesSampleRate: 0.5,
  sampleRate: 0.5,
  enabled: true,
  environment: window?._env_?.RUN_TIME_ENVIRONMENT,
  release: process.env.ENV_APP_VERSION,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Sentry.setTag("repo_version", "2.x");

// Sentry.captureException("Sentry Exception captured"); //For debugging

bootstrap(() => {})
